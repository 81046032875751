













































import { Component, Vue, PropSync } from 'vue-property-decorator';
import { SuccessChangeBankAccountDialogViewModel }
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/change-bank-account/success-change-bank-account-dialog-view-model';

@Component({ name: 'SuccessChangeBankAccount' })
export default class SuccessChangeBankAccount extends Vue {
  success_change_bank_account_dialog_view_model = Vue.observable(
    new SuccessChangeBankAccountDialogViewModel(this),
  );
}
